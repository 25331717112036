import { useEffect, useState } from "react"

type Props = {
  instant: boolean
  redirectUrl: string
  onCancel: () => void
}

export const Redirect = (props: Props) => {
  if (props.instant) {
    window.location.href = props.redirectUrl
    return <div></div>
  }

  const [isCancelled, setIsCancelled] = useState(false)

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      if (!isCancelled) {
        window.location.href = props.redirectUrl
      }
    }, 3000)

    return () => clearTimeout(redirectTimeout) // Clear the timeout on unmount or when isCancelled changes
  }, [isCancelled])

  let text = "Redirecting to " + props.redirectUrl
  if (isCancelled) {
    text = `Redirect to ${props.redirectUrl} cancelled`
  }

  return (
    <div>
      <p>
        <b className="font-bold">{text}</b>
        <br /><br />
        Note that anyone who is not logged in will be redirected instantly. This delay is to give you the chance to edit the code.
      </p>
      <button
        className="btn btn-primary btn-lg w-full"
        onClick={() => {
          setIsCancelled(true)
          props.onCancel()
        }}
      >
        Open Settings
      </button>
    </div>
  )
}
