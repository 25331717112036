import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { useForm, SubmitHandler } from "react-hook-form"
import TextInput from "../components/textInput"
import Swal from "sweetalert2"
import * as api from "../api"
import axios from "axios"

type LoginForm = {
  email: string
  password: string
}

export default function RequestPasswordResetRoute() {
  let navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>()

  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    try {
      await api.requestPasswordReset(data.email)
      Swal.fire("Email sent!", "Check your inbox for a link to reset your password", "success")
    } catch (e) {
      let message = String(e)
      if (axios.isAxiosError(e)) {
        message = e.response?.data.message
        if (e.response?.status === 404) {
          message = "No user with that email exists"
        }
      }
      Swal.fire("Error", message, "error")
    }
  }

  return (
    <div className="h-full w-full flex justify-center p-4">
      <div className="max-w-3xl w-full pb-16">
        <h1 className="text-xl font-bold">Reset Password</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
          <TextInput
            label="Email"
            placeholder="donald@dumb.people"
            type="email"
            error={errors.email?.type}
            {...register("email", { required: true })}
          />

          <input type="submit" className="btn btn-primary w-full mt-4" value="Reset my password" />
        </form>
      </div>
    </div>
  )
}
