import { Ctx } from "@milkdown/ctx"
import { tooltipFactory, TooltipProvider } from "@milkdown/plugin-tooltip"
import { toggleStrongCommand, toggleEmphasisCommand } from "@milkdown/preset-commonmark"
import { useInstance } from "@milkdown/react"
import { usePluginViewContext } from "@prosemirror-adapter/react"
import { useCallback, useEffect, useRef } from "react"
import { callCommand } from "@milkdown/utils"

export const tooltip = tooltipFactory("Text")

export const TooltipView = () => {
  const ref = useRef<HTMLDivElement>(null)
  const tooltipProvider = useRef<TooltipProvider>()

  const { view, prevState } = usePluginViewContext()
  const [loading, get] = useInstance()
  const action = useCallback(
    (fn: (ctx: Ctx) => void) => {
      if (loading) return
      get().action(fn)
    },
    [loading]
  )

  useEffect(() => {
    const div = ref.current
    if (loading || !div) {
      return
    }
    tooltipProvider.current = new TooltipProvider({
      content: div,
    })

    return () => {
      tooltipProvider.current?.destroy()
    }
  }, [loading])

  useEffect(() => {
    tooltipProvider.current?.update(view, prevState)
  })

  let btnClass = "btn btn-ghost btn-sm rounded-btn"

  return (
    <div data-desc="This additional wrapper is useful for keeping tooltip component during HMR">
      <div ref={ref} className="bg-primary rounded-xl">
        <button
          className={btnClass}
          onMouseDown={(e) => {
            e.preventDefault()
            action(callCommand(toggleStrongCommand.key))
          }}
        >
          <b>B</b>
        </button>
        <button
          className={btnClass}
          onMouseDown={(e) => {
            e.preventDefault()
            action(callCommand(toggleEmphasisCommand.key))
          }}
        >
          <em>I</em>
        </button>
      </div>
    </div>
  )
}
