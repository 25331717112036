import { VcardData, generateVcard } from "@/helpers/vcard"
import { useForm } from "react-hook-form"

type FormValues = {
  firstName: string
  lastName: string
  email: string
  phone: string
  organization: string
}

type Props = {
  onCancel: () => void
  onSubmit: (vcard: File, data: VcardData) => void
  open: boolean
}

export const VCardCreateModal = (props: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>()


  const onSubmit = handleSubmit((data) => {
    console.log(data)
    let vcard = generateVcard({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      organization: data.organization,
    })
    props.onSubmit(vcard, data)
  })

  const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    props.onCancel()
  }

  return (
    <dialog className="modal" open={props.open}>
      <form method="dialog" onSubmit={onSubmit} className="modal-box">
        <h2 className="font-bold text-2xl pb-2">Create VCard</h2>

        <div className="form-control w-full  ">
          <label className="label">
            <span className="label-text">Firstname</span>
          </label>
          <input type="text" placeholder="John" className="input input-bordered w-full" {...register("firstName")} />
        </div>
        <div className="form-control w-full  ">
          <label className="label">
            <span className="label-text">Lastname</span>
          </label>
          <input type="text" placeholder="Doe" className="input input-bordered w-full" {...register("lastName")} />
        </div>
        <div className="form-control w-full  ">
          <label className="label">
            <span className="label-text">Phone (optional)</span>
          </label>
          <input type="tel" placeholder="01234567" className="input input-bordered w-full" {...register("phone")} />
        </div>
        <div className="form-control w-full  ">
          <label className="label">
            <span className="label-text">Email (optional)</span>
          </label>
          <input type="tel" placeholder="john@example.com" className="input input-bordered w-full" {...register("email")} />
        </div>
        <div className="form-control w-full  ">
          <label className="label">
            <span className="label-text">Organisation (optional)</span>
          </label>
          <input type="text" placeholder="Type here" className="input input-bordered w-full" {...register("organization")} />
        </div>
        <input type="submit" className="btn btn-primary w-full mt-8" />
        <button className="btn w-full mt-2" value={"Create"} onClick={cancel}>
          Cancel
        </button>
      </form>
    </dialog>
  )
}
