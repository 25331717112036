import React from "react"
import { useNavigate } from "react-router-dom"
import { useForm, SubmitHandler } from "react-hook-form"
import TextInput from "../components/textInput"
import * as api from "../api"
import axios from "axios"
import Swal from "sweetalert2"
import { useVolatileStore } from "@/store"

type RegisterForm = {
  name: string
  email: string
  password: string
  passwordConfirm: string
}

export default function RegisterRoute() {
  let navigate = useNavigate()
  const volatileStore = useVolatileStore()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterForm>()

  const onSubmit: SubmitHandler<RegisterForm> = async (data) => {
    console.log("hey")
    if (data.password !== data.passwordConfirm) {
      alert("Passwords do not match")
      return
    }

    try {
      await api.register(data.email, data.name, data.password)
      await api.login(data.email, data.password)
      volatileStore.setUser(await api.getWhoAmI())
      let redirectTo = localStorage.getItem("loginRedirect") || "/"
      navigate(redirectTo)

      navigate(localStorage.getItem("loginRedirect") || "/")
    } catch (e) {
      let message = String(e)
      if (axios.isAxiosError(e)) {
        message = e.response?.data.message
      }
      Swal.fire("Error", message, "error")
    }
  }

  return (
    <div className="h-full w-full flex justify-center items-center p-4">
      <div className="max-w-3xl w-full pb-16">
        <h1 className="text-xl font-bold">Register</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
          <TextInput
            label="Name"
            placeholder="John Doe"
            type="text"
            autoComplete="name"
            error={errors.email?.type}
            {...register("name", { required: true })}
          />
          <TextInput
            label="Email"
            placeholder="donald@dumb.people"
            autoComplete="email"
            type="email"
            error={errors.email?.type}
            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
          />
          <TextInput
            label="Password"
            placeholder="thisisnotyourpassword"
            autoComplete="new-password"
            type="password"
            error={errors.password?.type}
            {...register("password", { required: true, minLength: 8 })}
          />
          <TextInput
            label="Confirm Password"
            placeholder="thisisnotyourpassword"
            autoComplete="new-password"
            type="password"
            error={errors.password?.type}
            {...register("passwordConfirm", { required: true, minLength: 8 })}
          />
          <input type="submit" className="btn btn-primary w-full mt-4" value="Register" />
          <button className="btn" onClick={()=>navigate("/login")} data-test-id="btn-login">Login instead</button>
        </form>
      </div>
    </div>
  )
}
