import axios from "axios"
import { User, Document, GetDocumentResponse, DocumentUpdateData, UploadFileResponse } from "./types"
import { UUID } from "@/types"
import { useNavigate } from "react-router-dom"

export function getAuthenticatedHeaders() {
  let token = localStorage.getItem("token")
  return {
    Authorization: `Bearer ${token}`,
  }
}

// const client = axios.create({ headers: getAuthenticatedHeaders() });

export async function login(email: string, password: string): Promise<string> {
  const resp = await axios.post("/api/auth/login", { email, password })
  if (!resp.data.token) {
    throw new Error("invalid credentials")
  }

  localStorage.setItem("token", resp.data.token)
  return resp.data.token
}

export async function register(email: string, name: string, password: string): Promise<string> {
  const resp = await axios.post("/api/auth/register", { email, password, name })
  return resp.data
}

export async function requestPasswordReset(email: string): Promise<string> {
  const resp = await axios.post("/api/auth/passwordReset/request", { email })
  return resp.data
}

export async function fulfillPasswordReset(resetToken: string, newPassword: string): Promise<string> {
  const resp = await axios.post("/api/auth/passwordReset/fulfill", { newPassword, resetToken })
  return resp.data
}

export async function getWhoAmI(): Promise<User> {
  const resp = await axios.get("/api/users/whoami", { headers: getAuthenticatedHeaders() })
  return resp.data
}

export async function isAuthenticated() {
  try {
    await getWhoAmI()
    return true
  } catch (e) {
    return false
  }
}

// document

export async function getDocuments() {
  const resp = await axios.get<Document[]>("/api/documents", { headers: getAuthenticatedHeaders() })
  return resp.data
}

export async function getDocument(documentId: UUID) {
  const resp = await axios.get<GetDocumentResponse>(`/api/documents/${documentId}`, { headers: getAuthenticatedHeaders() })
  return resp.data
}

export async function deleteDocument(documentId: UUID) {
  const resp = await axios.delete(`/api/documents/${documentId}`, { headers: getAuthenticatedHeaders() })
  return resp.data
}

export async function updateDocument(documentId: string, updatedDocument: DocumentUpdateData): Promise<Document> {
  const resp = await axios.put<Document>(`/api/documents/${documentId}`, updatedDocument, { headers: getAuthenticatedHeaders() })
  return resp.data
}

export async function uploadFile(documentId: UUID, file: File): Promise<UploadFileResponse> {
  const formData = new FormData()
  formData.append("file", file)
  const resp = await axios.post(`/api/documents/${documentId}/files`, formData, { headers: getAuthenticatedHeaders() })
  return resp.data
}

// admin only
export async function createDocument() {
  const resp = await axios.post<Document>(`/api/documents`, null, { headers: getAuthenticatedHeaders() })
  return resp.data
}

export function getFileUrl(documentId: UUID, fileId: UUID): string {
  return `/api/documents/${documentId}/files/${fileId}`
}
