import React from "react"
import * as api from "@/api"

type Props = {}

export default function Admin({}: Props) {
  const [newDocumentUrl, setNewDocumentUrl] = React.useState<string>("Url will appear here")

  const createDocument = async () => {
    let doc = await api.createDocument()
    setNewDocumentUrl(`${location.protocol}//${location.host}/d/${doc.id}`)
  }

  return (
    <div className="w-full h-full flex justify-center">
      <div className="h-full max-w-3xl w-full p-2">
        <h1 className="font-bold text-2xl">Admin Toolbox</h1>
        <div className="flex gap-2 items-center">
          <button className="btn btn-primary" onClick={createDocument}>Create document</button>
          <a href={newDocumentUrl} className="underline">{newDocumentUrl}</a>
        </div>
      </div>
    </div>
  )
}
