import React, { useEffect, useState } from "react"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faAngleRight, faBars, faHouse, faPerson, faUser } from "@fortawesome/free-solid-svg-icons"
import Swal from "sweetalert2"

import * as api from "@/api"
import { getUserOrNull } from "./helpers"
import { useVolatileStore } from "./store"

export default function Layout() {
  let location = useLocation()
  let navigate = useNavigate()
  const volatileStore = useVolatileStore()

  useEffect(() => {
    getUserOrNull().then((user) => {
      volatileStore.setUser(user)
    })
  }, [])

  const logout = () => {
    localStorage.removeItem("token")
    volatileStore.setUser(null)
    navigate("/login")
  }

  const changePassword = async () => {
    let user = await api.getWhoAmI()
    await api.requestPasswordReset(user.email)
    Swal.fire("Email sent", "Click the link we sent you to change your password", "success")
  }

  const isAuthenticated = volatileStore.user !== null

  const userMenuContent = isAuthenticated ? (
    <ul tabIndex={0} className="mt-3 p-2 shadow menu menu-sm dropdown-content bg-base-200 rounded-box w-52 z-50">
      <li className="">
        <span className="font-bold">{volatileStore.user?.email}</span>
      </li>
      <li>
        <a onClick={changePassword}>Change password</a>
      </li>
      <li>
        <a onClick={logout}>Logout</a>
      </li>
    </ul>
  ) : (
    <ul tabIndex={0} className="mt-3 p-2 shadow menu menu-sm dropdown-content bg-base-200 rounded-box w-52">
      <li>
        <a onClick={() => navigate("/login")}>Login</a>
      </li>
    </ul>
  )

  return (
    <div className="bg-base-100 min-h-screen flex flex-col">
      <div className="navbar bg-base-100 flex justify-between">
        <Link to={"/"}>
          {/* <FontAwesomeIcon icon={faHouse} className="text-2xl m-2 cursor-pointer" /> */}
          <img src="/logo_with_name.svg" className="h-12 mx-2 mt-2" />
        </Link>
        <div className="dropdown dropdown-end">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full">
              <FontAwesomeIcon icon={faUser} className="text-2xl m-2" />
            </div>
          </label>
          {userMenuContent}
        </div>
      </div>
      <Outlet />
      <div className="w-full flex justify-center">
        <div className="flex justify-center gap-4 pb-1 bg-base-200 w-min p-2 rounded-lg">
          <a href="https://noteqr.de/impressum" className="underline text-gray-500 text-md">
            Impressum
          </a>
          <a href="https://noteqr.de/privacy" className="underline text-gray-500 text-md">
            Datenschutzerklärung
          </a>
          <a href="mailto:support@noteqr.de" className="underline text-gray-500 text-md">
            Support
          </a>
        </div>
      </div>
    </div>
  )
}
