import { UUID } from "@/types"
import * as api from "@/api"
import { useEffect, useState } from "react"
import { useVolatileStore } from "@/store"
import { useRedirectToLogin } from "@/helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition, faEnvelope, faFilePdf, faFileText, faImage, faLink, faVcard } from "@fortawesome/free-solid-svg-icons"
import clsx from "clsx"
import Swal from "sweetalert2"
import { VcardData } from "@/helpers/vcard"
import { VCardCreateModal } from "./vcardCreateModal"
import { isAxiosError } from "axios"

export function ContentTypeButton(props: { name: string; icon: IconDefinition; active: boolean; onClick: () => void; testId?: string }) {
  const className = clsx(
    "w-full h-32 p-2 bg-white rounded-lg shadow-lg flex flex-col justify-center text-slate-700 cursor-pointer transition-all",
    {
      "outline outline-2 outline-primary !bg-primary/60": props.active,
    }
  )

  return (
    <div className={className} onClick={props.onClick} data-test-id={props.testId}>
      <FontAwesomeIcon icon={props.icon} size="2x" />
      <span className="w-full text-center pt-2">{props.name}</span>
    </div>
  )
}

type ContentType = "text" | "image" | "link" | "file" | "vcard" | "email"

type Props = {
  documentId: UUID
}
export default function ActivatePopup(props: Props) {
  const [isPublic, setIsPublic] = useState(true)
  const volatileStore = useVolatileStore()
  const redirectToLogin = useRedirectToLogin()
  const [selectedContentType, setSelectedContentType] = useState<ContentType>("text")
  const [isVcardCreateOpen, setIsVcardCreateOpen] = useState(false)
  const [isFilepickerOpen, setIsFilepickerOpen] = useState(false)
  const [isImageUpload, setIsImageUpload] = useState(false)

  const showNotAuthenticatedPopup = async () => {
    try {
      let result = await Swal.fire({
        title: "This is a new one!",
        text: "To set up this label, please login or create an account.",
        icon: "info",
        showDenyButton: true,
        confirmButtonText: "Login",
        denyButtonText: "Register",
        customClass: {
          confirmButton: "btn !btn-primary",
          denyButton: "btn !btn-primary",
          container: "!bg-white",
        },
      })
      if (result.isDenied) {
        redirectToLogin(true)
      } else {
        redirectToLogin()
      }
    } catch (e) {
      if (!String(e).includes("reading 'then'")) {
        throw e
      }
    }
  }

  useEffect(() => {
    if (volatileStore.user === null) {
      showNotAuthenticatedPopup()
    }
  }, [volatileStore.user])

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target
    if (!input.files) {
      return
    }

    const file = input.files.item(0)
    if (!file) {
      return
    }

    try {
      Swal.showLoading()
      await api.updateDocument(props.documentId, { content: file.name, isPublic })
      let fileResponse = await api.uploadFile(props.documentId, file)
      if (isImageUpload) {
        await api
          .updateDocument(props.documentId, {
            content: `# ${file.name}\n\n ![${file.name}](${api.getFileUrl(props.documentId, fileResponse.id)})`,
            isPublic,
          })
        window.location.reload()
      } else {
        await api
          .updateDocument(props.documentId, {
            content: file.name,
            isPublic,
            redirectUrl: api.getFileUrl(props.documentId, fileResponse.id),
          })
        window.location.reload()
      }
    } catch {
      console.log(e)
      if (isAxiosError(e) && e.response?.status == 413) {
        console.log("file too large")
        Swal.fire({
          title: "Error",
          text: "File too large",
          icon: "error"
        }).then(() => window.location.reload())
      }
    }
  }

  const activateDocument = async () => {
    if (!volatileStore.user) {
      redirectToLogin()
      return
    }

    if (selectedContentType === "text") {
      await api.updateDocument(props.documentId, { content: "# New label", isPublic })
      window.location.reload()
    } else if (selectedContentType === "image") {
      setIsImageUpload(true)
      setIsFilepickerOpen(true)
      /*       const input = document.createElement("input")
            input.type = "file"
            input.accept = "image/*"
            input.onchange = () => {
            }
            input.click() */
    } else if (selectedContentType === "link") {
      let result = await Swal.fire({
        title: "Enter link",
        input: "url",
        inputAttributes: { autocapitalize: "off" },
        showCancelButton: true,
        confirmButtonText: "Submit",
      })
      if (result.isConfirmed) {
        const link = result.value
        api.updateDocument(props.documentId, { content: "# Link", isPublic, redirectUrl: link })
      }
      window.location.reload()
    } else if (selectedContentType === "file") {
      setIsImageUpload(false)
      setIsFilepickerOpen(true)
    } else if (selectedContentType === "vcard") {
      setIsVcardCreateOpen(true)
    } else if (selectedContentType === "email") {
      let result = await Swal.fire({
        title: "Enter Email",
        input: "email",
        inputAttributes: { autocapitalize: "off" },
        showCancelButton: true,
        confirmButtonText: "Submit",
      })
      if (result.isConfirmed) {
        const link = `mailto:${result.value}`
        api.updateDocument(props.documentId, { content: "# Email", isPublic, redirectUrl: link })
      }
      window.location.reload()
    }
  }

  const onCreateVcard = async (vcard: File, data: VcardData) => {
    await api.updateDocument(props.documentId, { content: "VCard", isPublic })
    const res = await api.uploadFile(props.documentId, vcard)
    let vcardUrl = api.getFileUrl(props.documentId, res.id)
    let content = []
    content.push(`# ${data.firstName} ${data.lastName}\n\n`)
    if (data.email) content.push(`${data.email}\\\n`)
    if (data.phone) content.push(`${data.phone}\\\n`)
    if (data.address) content.push(`${data.address}, ${data.city} ${data.city}\n${data.country}\\\n`)
    if (data.organization) content.push(`${data.organization}\n\n`)
    content.push(`[VCard](${vcardUrl})`)
    await api.updateDocument(props.documentId, { content: content.join(""), isPublic })

    setIsVcardCreateOpen(false)
    window.location.reload()
  }

  const options: { name: string; icon: IconDefinition; contentType: ContentType }[] = [
    { name: "Text", icon: faFileText, contentType: "text" },
    { name: "Website", icon: faLink, contentType: "link" },
    { name: "Contact", icon: faVcard, contentType: "vcard" },
    { name: "File", icon: faFilePdf, contentType: "file" },
    { name: "Image", icon: faImage, contentType: "image" },
    { name: "Email", icon: faEnvelope, contentType: "email" },
  ]

  const renderedOptions = options.map((option) => (
    <ContentTypeButton
      key={option.name}
      name={option.name}
      icon={option.icon}
      active={selectedContentType === option.contentType}
      onClick={() => setSelectedContentType(option.contentType)}
      testId={`contentTypeBtn-${option.contentType}`}
    />
  ))

  return (
    <div className="grid justify-center items-center h-screen pb-32">
      <div className="flex flex-col items-center gap-2 max-w-md p-4">
        <h1 className="text-xl font-bold text-left">This is a new one!</h1>
        <p className="text-center">Click the button below to add this Label to your account</p>
        <div className="w-full grid grid-cols-3 gap-2 pb-4 transition-all">{renderedOptions}</div>
        <button className="btn btn-primary text-center" onClick={activateDocument} data-test-id="continueBtn">
          Continue
        </button>
      </div>
      <VCardCreateModal open={isVcardCreateOpen} onCancel={() => setIsVcardCreateOpen(false)} onSubmit={onCreateVcard} />
      <dialog id="my_modal_2" className="modal" open={isFilepickerOpen}>
        <form method="dialog" className="modal-box">
          <h3 className="font-bold text-lg">Select a file</h3>
          <input type="file" onChange={handleFileUpload} />
        </form>
        <form method="dialog" className="modal-backdrop">
          <button onClick={() => setIsFilepickerOpen(false)}>close</button>
        </form>
      </dialog>
    </div>
  )
}
