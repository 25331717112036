import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useForm, SubmitHandler } from "react-hook-form"
import TextInput from "../components/textInput"
import Swal from "sweetalert2"

import * as api from "../api"
import axios from "axios"

type RegisterForm = {
  name: string
  email: string
  password: string
  passwordConfirm: string
}

export default function FulfillPasswordResetRoute() {
  let navigate = useNavigate()
  const { token } = useParams()


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterForm>()

  const onSubmit: SubmitHandler<RegisterForm> = async (data) => {
    if (data.password !== data.passwordConfirm) {
      alert("Passwords do not match")
      return
    }

    try {
      await api.fulfillPasswordReset(token!, data.password)
      Swal.fire("Password reset!", "You can now login using the new password", "success")
      navigate("/login")
    } catch (e) {
      let message = String(e)
      if (axios.isAxiosError(e)) {
        message = e.response?.data.message
        if (e.response?.status === 404) {
          message = "Invalid token"
        }
      }
      Swal.fire("Error", message, "error")
    }
  }

  return (
    <div className="h-full w-full flex justify-center items-center p-4">
      <div className="max-w-3xl w-full pb-16">
        <h1 className="text-xl font-bold">Reset Password</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
          <TextInput
            label="Password"
            placeholder="thisisnotyourpassword"
            autoComplete="new-password"
            type="password"
            error={errors.password?.type}
            {...register("password", { required: true, minLength: 8 })}
          />
          <TextInput
            label="Confirm Password"
            placeholder="thisisnotyourpassword"
            autoComplete="new-password"
            type="password"
            error={errors.password?.type}
            {...register("passwordConfirm", { required: true, minLength: 8 })}
          />
          <input type="submit" className="btn w-full mt-4" value="Save" />
        </form>
      </div>
    </div>
  )
}
