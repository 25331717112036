import { User } from "@/api/types"
import { create } from "zustand"

interface VolatileState {
  user: User | null | undefined
  setUser: (user: User | null | undefined) => void
}

export const useVolatileStore = create<VolatileState>()(set=>({
  user: undefined,
  // undefined means we don't know if the user is authenticated or not
  setUser: (user: User | null | undefined) => set({ user })
}))
