export interface VcardData {
  firstName: string
  lastName: string
  organization?: string
  email?: string
  phone?: string
  website?: string
  address?: string
  city?: string
  state?: string
  zip?: string
  country?: string
}

export function generateVcard(data: VcardData) {
  let vcard = []
  vcard.push(`BEGIN:VCARD`)
  vcard.push(`VERSION:3.0`)
  vcard.push(`N:${data.lastName};${data.firstName};;`)
  vcard.push(`FN:${data.firstName} ${data.lastName}`)
  if (data.organization) vcard.push(`ORG:${data.organization}`)
  if (data.email) vcard.push(`EMAIL;TYPE=PREF,INTERNET:${data.email}`)
  if (data.phone) vcard.push(`TEL;TYPE=HOME,VOICE:${data.phone}`)
  if (data.website) vcard.push(`URL:${data.website}`)
  if (data.address) vcard.push(`ADR;TYPE=HOME:;;${data.address};${data.city};${data.state};${data.zip};${data.country}`)
  vcard.push(`REV:${new Date().toISOString()}`)
  vcard.push(`END:VCARD`)
  const textContent = vcard.join('\n')

  return new File([textContent], `${data.firstName}.vcf`, { type: "text/vcard" });
}
