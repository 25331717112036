import { commandsCtx, defaultValueCtx, Editor, editorViewOptionsCtx, rootCtx } from "@milkdown/core"
import { commonmark } from "@milkdown/preset-commonmark"
import { Milkdown, useEditor, useInstance } from "@milkdown/react"
import { nord } from "@milkdown/theme-nord"
import { callCommand, insert } from "@milkdown/utils"
import { usePluginViewFactory, useWidgetViewFactory } from "@prosemirror-adapter/react"
import { listener, listenerCtx } from "@milkdown/plugin-listener"
import { history, undoCommand } from "@milkdown/plugin-history"
import { clipboard } from "@milkdown/plugin-clipboard"
import { gfm } from "@milkdown/preset-gfm"
import { upload, uploadConfig, Uploader } from "@milkdown/plugin-upload"
import type { Node } from "@milkdown/prose/model"

import { tooltip, TooltipView } from "./tooltip"

import "@milkdown/theme-nord/style.css"
import { linkPlugin } from "./linkPlugin"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import Swal from "sweetalert2"
import { isAxiosError } from "axios"

type Props = {
  markdown: string
  editable: boolean
  onChange: (markdown: string) => void
  uploadFile: (file: File) => Promise<string>
}

export const MilkdownEditor = (props: Props) => {
  const pluginViewFactory = usePluginViewFactory()
  const widgetViewFactory = useWidgetViewFactory()
  const editor = useInstance()[1]()

  let initialMarkdown = props.markdown
  if (initialMarkdown.trim() === "") {
    initialMarkdown = "# New Code \n..."
  }

  const undo = () => {
    console.log("not implemented")
    //    console.log("undo")
    //   editor?.action((ctx)=>{
    //     const commandManager = ctx.get(commandsCtx)
    //     commandManager.call(undoCommand)
    //   })
  }

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let input = event.target
    if (!input.files) {
      return
    }

    const file = input.files.item(0)
    if (!file) {
      Swal.fire({ title: "No file selected", icon: "error" })
      return
    }

    try {
      Swal.showLoading()
      let src = await props.uploadFile(file)
      if (file.type.includes("image")) {
        editor?.action(insert(`![${file.name}](${src})`))
      } else {
        editor?.action(insert(`[${file.name}](${src})`))
      }
      Swal.close()
    } catch (e) {
      if (isAxiosError(e)) {
        Swal.fire({ title: "error uploading", text: e.response?.status.toString(), icon: "error" })
      } else {
        Swal.fire({ title: "error", text: String(e), icon: "error" })
      }
    }
    input.click()
  }

  const uploader: Uploader = async (files, schema) => {
    const images: File[] = []
    const otherFiles: File[] = []

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)
      if (!file) {
        continue
      }

      // You can handle whatever the file type you want, we handle image here.
      if (file.type.includes("image")) {
        images.push(file)
      } else {
        otherFiles.push(file)
      }
    }

    const imageNodes: Node[] = await Promise.all(
      images.map(async (image) => {
        const src = await props.uploadFile(image)
        const alt = image.name
        return schema.nodes.image.createAndFill({
          src,
          alt,
        }) as Node
      })
    )

    await Promise.all(
      otherFiles.map(async (file) => {
        const src = await props.uploadFile(file)
        editor?.action(insert(`[${file.name}](${src})`))
      })
    )

    return imageNodes
  }

  useEditor((root) => {
    let editor = Editor.make()
      .config((ctx) => {
        ctx.set(rootCtx, root)
        ctx.set(defaultValueCtx, initialMarkdown)
        ctx.set(tooltip.key, {
          view: pluginViewFactory({
            component: TooltipView,
          }),
        })
        // ctx.set(hardbreakKeymap.key, {
        //   InsertHardbreak: 'Enter',
        // })
        ctx.update(editorViewOptionsCtx, (prev) => ({
          ...prev,
          editable: () => props.editable,
        }))
        ctx.update(uploadConfig.key, (prev) => ({
          ...prev,
          uploader,
        }))
        ctx.get(listenerCtx).markdownUpdated((ctx, markdown, prevMarkdown) => {
          props.onChange(markdown)
        })

      })
      .config(nord)
      .use(commonmark)
      .use(gfm)
      .use(tooltip)
      .use(listener)
      .use(history)
      .use(clipboard)
      .use(upload)
      .use(linkPlugin(widgetViewFactory))
    return editor
  }, [])

  return (
    <div>
      <Milkdown />
      {props.editable &&
        <div className="fixed bottom-0 right-0 p-4" spellCheck="false">
          <input type="file" className="hidden" id="fileUpload" onChange={handleFileUpload} />
          <label className="btn" htmlFor="fileUpload">Add file</label>
        </div>
      }
    </div>
  )
}
