import * as api from "@/api"
import { User } from "@/api/types"
import { useVolatileStore } from "@/store"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export const getUserOrNull = async (): Promise<User | null> => {
  if (!localStorage.getItem("token")) {
    return null
  }

  try {
    let res = await api.getWhoAmI()
    return res
  } catch (e) {
    return null
  }
}

export const useRedirectToLogin = () => {
  const navigate = useNavigate()

  return (register?: boolean)=>{
    localStorage.setItem("loginRedirect", location.pathname === "/login" ? "/" : location.pathname)
    navigate(register ? "/register" : "/login")
  }
}



// this is broken badly
// export const useAuthRequired = () => {
//   const navigate = useNavigate()
//   const volatileStore = useVolatileStore()

//   if (volatileStore.user === undefined) {
//     return true
//   }

//   if (volatileStore.user === null) {
//     console.log("user is null")
//     localStorage.setItem("loginRedirect", location.pathname)
//     location.href = "/login"
//     return false // Return false if user is not authenticated
//   }

//   return true // Return true if user is authenticated
// }
