import { useNavigate } from "react-router-dom"

type Props = {
  setIsOpen(isOpen: boolean): void
  open: boolean
}

export const HelpModal = (props: Props) => {
  const navigate = useNavigate()
  const close = () => {
    props.setIsOpen(false)
  }

  const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    props.setIsOpen(false)
    console.log("cancel")
  }

  return (
    <dialog className="modal" open={props.open}>
      <form method="dialog" className="modal-box">
        <h2 className="font-bold text-2xl pb-2">How to format</h2>
        <h3 className="font-bold">Headings</h3>
        <p>Add heading by typing a #</p>
        <div className="bg-slate-800 p-2 rounded-lg text-white">
          <pre>
            <code># Heading 1</code>
          </pre>
          <pre>
            <code>## Heading 2</code>
          </pre>
          <pre>
            <code>### Heading 3</code>
          </pre>
        </div>
        <h3 className="font-bold pt-2">Lists</h3>
        <div className="bg-slate-800 p-2 rounded-lg text-white">
          <pre>
            <code>- List item 1</code>
          </pre>
          <pre>
            <code>- This will be nicely formatted</code>
          </pre>
          <pre>
            <code>- with dots</code>
          </pre>
        </div>
        <p>You can also do numbered lists.</p>
        <h3 className="font-bold pt-2">Bold & Italic</h3>
        <p>To make some text bold or italic, select it and you will see a popup where you can select bold or italic.</p>
        <h3 className="font-bold pt-2">Links</h3>
        <p>Any URL you paste in the editor will automatically be clickable. To create named links:</p>
        <div className="bg-slate-800 p-2 rounded-lg text-white">
          <pre>
            <code>[Link name](https://example.com)</code>
          </pre>
        </div>

        <button className="btn btn-neutral w-full mt-8" onClick={cancel}>
          Close{" "}
        </button>
      </form>
      <form method="dialog" className="modal-backdrop">
        <button onClick={() => props.setIsOpen(false)}>close</button>
      </form>
    </dialog>
  )
}
