import { SubmitHandler, useForm } from "react-hook-form"
import * as api from "@/api"
import { GetDocumentResponse } from "@/api/types"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

type Props = {
  // modalRef: React.RefObject<HTMLDialogElement>
  setIsOpen(isOpen: boolean): void
  open: boolean
  document: GetDocumentResponse
  onUpdated: () => void
}

type SettingsForm = {
  isPublic: boolean
  isPublicWrite: boolean
  redirect: boolean
  redirectUrl: string
}

export const DocumentSettings = (props: Props) => {
  const navigate = useNavigate()

  const defaultValues = () => {
    return {
      isPublic: props.document.document.isPublic,
      isPublicWrite: props.document.document.isPublicWrite,
      redirect: props.document.document.redirectUrl !== "",
      redirectUrl: props.document.document.redirectUrl,
    }
  }

  const {
    register,
    handleSubmit,
    reset,
    watch
  } = useForm<SettingsForm>({
    defaultValues: defaultValues(),
  })

  const onSubmit: SubmitHandler<SettingsForm> = async (data) => {
    data.redirectUrl = data.redirect ? data.redirectUrl : ""

    await api.updateDocument(props.document.document.id, { ...props.document.document, ...data })
    props.onUpdated()
    props.setIsOpen(false)
  }

  const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    props.setIsOpen(false)
    console.log("cancel")
  }

  const removeFromAccount = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    await api.deleteDocument(props.document.document.id)
    
    navigate("/")
    props.setIsOpen(false)
  }

  useEffect(() => {
    reset(defaultValues())
  }, [props.open])

  return (
    <dialog className="modal" open={props.open}>
      <form onSubmit={handleSubmit(onSubmit)} className="modal-box">
        <h3 className="font-bold text-lg">Code settings</h3>
        <p className="py-4">Configure your QR Code</p>
        <div className="form-control w-full">
          <label className="cursor-pointer label">
            <span className="label-text">Anyone can read</span>
            <input type="checkbox" className="toggle" {...register("isPublic")} />
          </label>
          <label className="cursor-pointer label">
            <span className={watch("isPublic") ? "label-text" : "label-text text-gray-300"}>Anyone can write</span>
            <input type="checkbox"  disabled={!watch("isPublic")}  className="toggle transition-all" {...register("isPublicWrite")} />
          </label>
          <label className="cursor-pointer label">
            <span className="label-text">Redirect this QR Code to another website</span>
            <input type="checkbox" className="toggle" {...register("redirect")} />
          </label>
          <label className="cursor-pointer label flex gap-4">
            <span className="label-text whitespace-nowrap">Redirect URL</span>
            <input
              type="url"
              placeholder="https://google.com"
              className="input input-sm input-bordered w-full"
              {...register("redirectUrl")}
            />
          </label>
        </div>
        <div className="modal-action flex justify-between">
          <div>
            <button onClick={removeFromAccount} className="btn btn-error">
              Remove from account
            </button>
          </div>
          <div>
            <button onClick={cancel} className="btn">
              Cancel
            </button>
            <input type="submit" className="btn btn-primary" value="Save" />
          </div>
        </div>
      </form>
    </dialog>
  )
}
