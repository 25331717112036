import { faLink } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Plugin } from "@milkdown/prose/state"
import { DecorationSet } from "@milkdown/prose/view"
import { $prose } from "@milkdown/utils"
import type { useWidgetViewFactory } from "@prosemirror-adapter/react"
import { useWidgetViewContext } from "@prosemirror-adapter/react"
import type { FC } from "react"

export const LinkWidget: FC = () => {
  const { spec } = useWidgetViewContext()
  const href: string = spec?.href ?? ""

  return (
    <span className="not-prose">
      <a
        href={href}
        rel="noreferrer"
        className={"inline-flex items-center justify-center gap-1 rounded px-2"}
        onMouseDown={(e) => {
          e.preventDefault()
        }}
      >
        <span className="material-symbols-outlined text-sm text-nord8 "><FontAwesomeIcon icon={faLink} /></span>
        <small className="font-light text-nord8">Open Link</small>
      </a>
    </span>
  )
}

export const linkPlugin = (widgetViewFactory: ReturnType<typeof useWidgetViewFactory>) => {
  const widget = widgetViewFactory({ as: "span", component: LinkWidget })

  return $prose(
    () =>
      new Plugin({
        state: {
          init() {
            return DecorationSet.empty
          },
          apply(tr) {
            const { selection } = tr

            const { $from, $to } = selection
            const node = tr.doc.nodeAt(selection.from)

            const mark = node?.marks.find((mark) => mark.type.name === "link")

            if (!mark) return DecorationSet.empty

            let markPos = { start: -1, end: -1 }
            tr.doc.nodesBetween($from.start(), $to.end(), (n, pos) => {
              if (node === n) {
                markPos = {
                  start: pos,
                  end: pos + Math.max(n.textContent.length, 1),
                }

                // stop recursing if result is found
                return false
              }
              return undefined
            })

            return DecorationSet.create(tr.doc, [
              widget(markPos.end, {
                href: mark.attrs.href,
                title: mark.attrs.title,
              }),
            ])
          },
        },
        props: {
          decorations(state) {
            return this.getState(state)
          },
        },
      })
  )
}
