import { useNavigate } from "react-router-dom"

type Props = {
  setIsOpen(isOpen: boolean): void
  open: boolean
}

export const AddCode = (props: Props) => {
  const navigate = useNavigate()
  const close = () => {
    props.setIsOpen(false)
  }

  const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    props.setIsOpen(false)
    console.log("cancel")
  }

  return (
    <dialog className="modal" open={props.open}>
      <form method="dialog" className="modal-box">
        <h3 className="font-bold text-lg">What do you want to do?</h3>
        <div className="flex flex-col gap-4 md:gap-0 md:flex-row">
          <div className="flex-1">
            <h2 className="font-bold">Buy new Codes</h2>
            <a className="btn btn-secondary" href="https://l.noteqr.de/buy_from_app">
              Buy Codes here
            </a>
          </div>
          <div className="flex-1">
            <h2 className="font-bold">Add a Code</h2>
            <p>
              Use your normal qr code scanner to add codes. If you do not have one, you may be able to use your camera app, "Binary Eye"
              (Android) or Google Lens.
            </p>
          </div>
        </div>
        <button className="btn btn-neutral w-full mt-8" onClick={cancel}>
          Close{" "}
        </button>
      </form>
      <form method="dialog" className="modal-backdrop">
        <button onClick={() => props.setIsOpen(false)}>close</button>
      </form>
    </dialog>
  )
}
