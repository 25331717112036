import { useEffect, useState } from "react"
import { MilkdownProvider } from "@milkdown/react"
import { MilkdownEditor } from "../components/editor"
import { ProsemirrorAdapterProvider } from "@prosemirror-adapter/react"
import { useParams } from "react-router-dom"
import { GetDocumentResponse } from "@/api/types"
import * as api from "@/api"
import axios from "axios"
import ActivatePopup from "@/components/activatePopup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog, faEye, faLock, faPen } from "@fortawesome/free-solid-svg-icons"
import { DocumentSettings } from "@/components/documentSettings"
import { Redirect } from "@/components/redirect"
import { useDebouncedCallback } from "use-debounce"
import { HelpModal } from "@/components/helpModal"
import clsx from "clsx"

export default function DocumentRoute() {
  const { documentId } = useParams()

  let [doc, setDoc] = useState<GetDocumentResponse | null>(null)
  let [isActivated, setIsActivated] = useState(true)
  let [isForbidden, setIsForbidden] = useState(false)
  let [isSettingsOpen, setIsSettingsOpen] = useState(false)
  let [isHelpOpen, setIsHelpOpen] = useState(false)
  let [lastStatusUpdate, setLastStatusUpdate] = useState<number>(new Date().getTime())
  const [statusText, setStatusText] = useState<string>("")
  const [statusType, setStatusType] = useState<"success" | "error">("success")

  const loadDoc = async () => {
    try {
      setDoc(await api.getDocument(documentId!))
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.log(e)
        if (e.response?.status === 406) {
          console.log("Document not activated yet")
          setIsActivated(false)
        } else if (e.response?.status === 403) {
          console.log("Forbidden")
          setIsForbidden(true)
        }
      }
    }
  }

  const onChangeContent = useDebouncedCallback((markdown: string) => {
    api
      .updateDocument(doc!.document.id, { ...doc!.document, content: markdown })
      .then(() => {
        setLastStatusUpdate(new Date().getTime())
        setStatusType("success")
        setStatusText("Saved.")
        //setTimeout(() => {
        //}, 2000)
      })
      .catch((e) => {
        setStatusType("error")
        setStatusText("Failed to save!")
        throw e
      })
  }, 200)



  const uploadFile = async (file: File) => {
    let res = await api.uploadFile(documentId!, file)
    return api.getFileUrl(documentId!, res.id)
  }

  useEffect(() => {
    loadDoc()

    // clear status if no new updates
    let x = setInterval(() => {
      if (new Date().getTime() - lastStatusUpdate > 1000) {
        setStatusText("")
      }
    }, 300)

    return () => clearInterval(x)
  }, [lastStatusUpdate])

  useEffect(() => {
    if (!localStorage.getItem("introShown") && (doc?.isOwner || doc?.document.isPublicWrite)) {
      setIsHelpOpen(true)
      localStorage.setItem("introShown", "true")
    }
  }, [doc])

  if (isForbidden) {
    return (
      <div className="grid justify-center items-center h-full">
        <div className="flex flex-col items-center gap-2">
          <FontAwesomeIcon icon={faLock} size="3x" />
          <h1 className="text-xl font-bold text-center">This document is private</h1>
          <p className="text-center">You do not have permission to view this document</p>
        </div>
      </div>
    )
  }

  if (!isActivated) {
    return <ActivatePopup documentId={documentId!} />
  }

  // skeleton loader
  if (!doc) {
    return (
      <div className="w-full flex justify-center">
        <div className="p-4 w-full max-w-2xl">
          <p className="m-0 bg-base-200 text-transparent rounded-xl animate-pulse w-4/6"> .</p>
          <div className="h-full w-full outline-none prose prose-li:-mt-3">
            <h1 className="bg-base-200 text-transparent rounded-xl animate-pulse w-full">.</h1>
            <p className="bg-base-200 text-transparent rounded-xl animate-pulse w-full">.</p>
          </div>
        </div>
      </div>
    )
  }

  const isEditable = doc.isOwner || doc.document.isPublicWrite
  let infoText = "You do not own this code"
  if (isEditable && !doc.isOwner) {
    infoText = "You can edit this code"
  } else if (doc.isOwner) {
    infoText = "You own this code"
  }

  let content = (
    <div className="h-full w-full outline-none prose prose-p:my-1 prose-h1:mb-3">
      <MilkdownProvider>
        <ProsemirrorAdapterProvider>
          <MilkdownEditor markdown={doc.document.content} onChange={onChangeContent} editable={isEditable} uploadFile={uploadFile} />
        </ProsemirrorAdapterProvider>
      </MilkdownProvider>
    </div>
  )

  if (doc.document.redirectUrl) {
    content = <Redirect onCancel={() => setIsSettingsOpen(true)} redirectUrl={doc.document.redirectUrl} instant={!doc.isOwner} />
  }

  return (
    <div className="w-full flex justify-center">
      <div className="p-4 w-full max-w-2xl">
        <header className="flex justify-between">
          <p className="m-0 text-gray-500">
            <FontAwesomeIcon icon={isEditable ? faPen : faEye} className="pr-2" size="sm" />
            <span className="select-none">{infoText}</span>
          </p>
          {doc.isOwner && (
            <p className="m-0 text-gray-500 cursor-pointer" onClick={() => setIsSettingsOpen(true)}>
              <FontAwesomeIcon icon={faCog} className="pr-2" size="sm" />
              <span className="underline select-none">Settings</span>
            </p>
          )}
        </header>

        {content}
      </div>
      <DocumentSettings open={isSettingsOpen} setIsOpen={setIsSettingsOpen} document={doc} onUpdated={loadDoc} />
      <HelpModal open={isHelpOpen} setIsOpen={setIsHelpOpen} />
      <div
        className={clsx(
          "fixed top-4 py-1 px-4 bg-green-400 rounded-full text-center font-semibold text-white transform transition-all",
          { "-translate-y-10": statusText.length === 0, "bg-red-400": statusType === "error" }
        )}
      >
        {statusText}
      </div>
    </div>
  )
}
