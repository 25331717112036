import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { useForm, SubmitHandler } from "react-hook-form"
import TextInput from "../components/textInput"
import Swal from "sweetalert2"
import * as api from "../api"
import axios from "axios"
import { useVolatileStore } from "@/store"

type LoginForm = {
  email: string
  password: string
}

export default function LoginRoute() {
  let navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>()
  const volatileStore = useVolatileStore()

  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    try {
      await api.login(data.email, data.password)
      volatileStore.setUser(await api.getWhoAmI())
      let redirectTo = localStorage.getItem("loginRedirect") || "/"
      navigate(redirectTo)
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 401) {
        Swal.fire("Invalid credentails", "Make sure you entered the correct email and password", "error")
      } else {
        Swal.fire("Error", String(e), "error")
      }
    }
  }

  if (volatileStore.user) {
    return <div>You are already logged in.</div>
  }

  return (
    <div className="h-full w-full flex justify-center items-center p-4">
      <div className="max-w-3xl w-full pb-16">
        <h1 className="text-xl font-bold">Login</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
          <TextInput
            label="Email"
            placeholder="donald@dumb.people"
            type="email"
            error={errors.email?.type}
            {...register("email", { required: true })}
          />
          <TextInput
            label="Password"
            placeholder="thisisnotyourpassword"
            type="password"
            error={errors.password?.type}
            {...register("password", { required: true })}
          />
          <input type="submit" className="btn btn-primary w-full mt-4" value="Login" />
        </form>
        <div className="w-full flex justify-end pt-2">
          <div className="flex flex-col sm:flex-row">
            <Link className="btn btn-secondary" to={"/register"}>
              I do not have an account
            </Link>
            <Link className="btn btn-link" to={"/passwordReset/request"}>
              Forgot Password?
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
