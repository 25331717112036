import { Document } from "@/api/types"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { formatDistance } from "date-fns"
import Fuse from "fuse.js"
import * as api from "@/api"
import { useVolatileStore } from "@/store"
import { useRedirectToLogin } from "@/helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { AddCode } from "@/components/addCode"

export default function Home() {
  const volatileStore = useVolatileStore()
  const redirectToLogin = useRedirectToLogin()

  const [docs, setDocs] = useState<Document[]>([])
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [searchResults, setSearchResults] = useState<Document[]>([])
  let [isAddDialogOpen, setIsAddDialogOpen] = useState(false)


  useEffect(() => {
    console.log(volatileStore.user)
    if (volatileStore.user === undefined) {
      return
    }
    if (volatileStore.user === null) {
      redirectToLogin()
      return
    }
    api.getDocuments().then(setDocs)
  }, [volatileStore.user])

  useEffect(() => {
    const fuse = new Fuse(docs, { keys: ["content"] })
    setSearchResults(searchQuery ? fuse.search(searchQuery).map((result) => result.item) : docs)
  }, [docs, searchQuery])

  const getPreviewForDoc = (doc: Document) => {
    return doc.content.split("\n")[0].replace("# ", "").replaceAll("\u0026#x20;", "").trim() || <em>No content</em>
  }

  const onClickAdd = () => {
    setIsAddDialogOpen(true)
  }

  const renderedDocs = searchResults.map((doc) => (
    <Link to={`/d/${doc.id}`} key={doc.id}>
      <div className="p-2 border-b-2 cursor-pointer flex justify-between select-none">
        <div>{getPreviewForDoc(doc)}</div>
        <div className="text-gray-500 text-sm">{formatDistance(new Date(doc.updatedAt), new Date(), { addSuffix: true })}</div>
      </div>
    </Link>
  ))

  const emptyComponent = (
    <div>
      <p>Your codes will appear here.</p>
      <p>Start by scanning a code using your normal QR Code Scanner</p>
    </div>
  )

  return (
    <div className="w-full h-full flex justify-center">
      <div className="h-full max-w-3xl w-full p-2">
        <div className="flex justify-between items-center pb-2">
          <h1 className="font-bold text-3xl">Your Codes</h1>
          <button className="btn btn-secondary btn-sm" onClick={onClickAdd}>
            <FontAwesomeIcon icon={faAdd} />
          </button>
        </div>
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="p-2 border-2 rounded-md mb-2 w-full"
          data-test-id="search-input"
        />
        <div className="flex flex-col gap-2" data-test-id="search-results">{renderedDocs.length > 0 ? renderedDocs : emptyComponent}</div>
      </div>
      <AddCode open={isAddDialogOpen} setIsOpen={setIsAddDialogOpen} />
    </div>
  )
}
