import { ComponentPropsWithoutRef, forwardRef } from "react"

interface Props extends ComponentPropsWithoutRef<"input"> {
  label: string
  placeholder?: string
  error?: string
}

export default forwardRef(function TextInput(props: Props, ref: React.ForwardedRef<HTMLInputElement>) {
  const { label, placeholder, error, ...rest } = props
  return (
    <div className="w-full">
      <label className="flex flex-col gap-1 text-left">
        {/* <span className="label-text">{label} {error && (<span>- <span className="text-error">{error}</span></span>)}</span> */}
        <span>
          <span className="label-text">{label}</span>
          {error && (
            <span>
              <span className="label-text"> - </span>
              <span className="text-error">{error}</span>
            </span>
          )}
        </span>
        <input ref={ref} placeholder={placeholder} className="input input-bordered w-full bg-base-300" {...rest} />
      </label>
    </div>
  )
})
