import React from "react"
import ReactDOM from "react-dom/client"
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom"
import "./index.css"
import Layout from "@/layout"
import HomeRoute from "@/routes/home"
import DocumentRoute from "@/routes/document"
import LoginRoute from "@/routes/login"
import * as Sentry from "@sentry/react"
import RegisterRoute from "./routes/register"
import RequestPasswordResetRoute from "./routes/requestPasswordReset"
import FulfillPasswordResetRoute from "./routes/fulfillPasswordReset"
import FailRoute from "./routes/fail"
import Admin from "./routes/admin"

export const runApp = () => {
  if (window.location.hostname === "app.noteqr.de") {
    console.log("running in production, initializing sentry")
  } else {
    console.log("not running in production, not initializing sentry")
  }

  const enable = window.location.hostname.endsWith("noteqr.de")
  const prod = window.location.hostname === "app.noteqr.de"

  Sentry.init({
    dsn: "https://954578d0b7cb4256be52a2e838b8ccd3@o574693.ingest.sentry.io/4505340316745728",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/app\.noteqr.de\/api/],
      }),
      new Sentry.Replay(),
    ],
    enabled: enable,
    environment: prod ? "production" : "staging",
    // Performance Monitoring
    tracesSampleRate: prod ? 0.1 : 1,
    // Session Replay
    replaysSessionSampleRate: prod ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

  try {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for (let registration of registrations) {
        registration.unregister()
      }
    })
  } catch { }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <HomeRoute />,
        },
        {
          path: "/admin",
          element: <Admin />,
        },
        {
          path: "/d/:documentId",
          element: <DocumentRoute />,
        },
        {
          path: "/login",
          element: <LoginRoute />,
        },
        {
          path: "/register",
          element: <RegisterRoute />,
        },
        {
          path: "/passwordReset/request",
          element: <RequestPasswordResetRoute />,
        },
        {
          path: "/passwordReset/fulfill/:token",
          element: <FulfillPasswordResetRoute />,
        },
        {
          path: "/fail",
          element: <FailRoute />,
        },
      ],
    },
  ])

  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  )
}
